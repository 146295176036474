import { graphql } from 'gatsby';
import React from 'react';
import DownloadIcon from '../../images/icons/file_download.inline.svg';
import ArrowRight from '../../images/icons/arrow_right_alt.inline.svg';
import tw, { css } from 'twin.macro';

const Docs = ({ data, index }) => (
  <div
    tw="max-w-screen-md px-6 mx-auto py-6"
    css={[
      css`
        &:last-child {
          ${tw`pb-24 2xl:pb-40`}
        }
      `,
      index === 0 && tw`pt-12`,
    ]}
  >
    {data.title && (
      <h3 tw="text-3xl leading-none font-bold text-brand-green mb-6 lg:text-4xl">
        {data.title}
      </h3>
    )}
    <ul>
      <li tw="hidden font-bold text-sm text-brand-green md:flex">
        <p tw="w-16 flex-grow-0 flex-shrink-0 xl:w-20">Country</p>
        <p tw="flex-grow pl-4 xl:pl-0">File name</p>
        <p tw="pr-4">File size</p>
        <div tw="w-12 xl:w-20"></div>
      </li>
      {data.file &&
        data.file.map((f) => (
          <li
            key={`repo_${f.id}`}
            tw="w-full relative"
            css={css`
              &:after {
                content: '';
                ${tw`block absolute left-16 right-0 bottom-0 border-b border-brand-green xl:left-20`}
              }
            `}
          >
            <a
              href={f.file.localFile.publicURL}
              target="_blank"
              rel="noreferrer"
              tw="flex w-full py-4"
            >
              <span tw="py-1 bg-brand-purple-800 text-white flex items-center justify-center w-12 font-bold rounded-md text-center flex-grow-0 flex-shrink-0">
                {f.country}
              </span>
              <div tw="px-4 flex-grow md:(flex items-center) xl:pl-8">
                <p tw="font-thin text-base leading-tight text-brand-purple-800 flex-grow relative pl-4">
                  <ArrowRight tw="absolute left-0 top-0 w-4 -ml-1 mr-1" />

                  {f.name}
                </p>
                <p tw="font-thin text-brand-purple-800 text-sm pl-4">
                  {f.file.localFile.prettySize}
                </p>
              </div>
              <div tw="flex items-center justify-end flex-shrink-0 w-12 xl:w-20">
                <span tw="p-1 pointer-events-none rounded-full block bg-brand-green text-white">
                  <DownloadIcon tw="w-6 h-6" />
                </span>
              </div>
            </a>
          </li>
        ))}
    </ul>
  </div>
);

export const query = graphql`
  fragment Documents on STRAPI_ComponentContainersDocuments {
    id
    title
    file {
      id
      country
      name
      file {
        url
        localFile {
          publicURL
          prettySize
        }
      }
    }
  }
`;

export default Docs;
